export interface Dashboard{
    status: boolean;
    message?: string;
    architecture_types: number;
    projects:number;
    properties:number;
    amenities:number;
    leads:number;
    project_amenities:number;
    property_amenities:number;




}