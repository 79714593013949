export interface ProjectAmenity {
    tableId?: number;
    id: number;
    name: string;
    approved: number;
    approved_by: number | string;
    picture?: string;
    created_by: number;
    created_at: string;
    updated_at: string;
}