import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './confirm-modal-dialog.component.html',
  styleUrl: './confirm-modal-dialog.component.scss'
})
export class ConfirmModalDialogComponent implements OnDestroy, OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
