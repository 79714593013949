export interface ProjectCategory {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export function formatProjectCategoryJson(json: any): ProjectCategory {
    const projectCategory: ProjectCategory = {
        id: json.id,
        name: json.name,
        createdAt: new Date(json.created_at),
        updatedAt: new Date(json.updated_at)
    }
    return projectCategory;
}