<div class="create-dialog-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-end">
        <button class="close-btn bg-transparent p-0 border-none"
        [mat-dialog-close]="false">
            <i class="flaticon-close"></i>
        </button>
    </div>
    <mat-dialog-content>
        <form>
            <h5 class="mx-auto body-text">{{data.message}}</h5>
            <div class="text-end">
                <button type="button" class="gray" [mat-dialog-close]="false">
                    <i class="ri-close-fill"></i> Close
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>