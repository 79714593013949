import { MediaLibraryItem } from "./media-library-item.entity";
import { Plan, formatPlanJson } from "./plan.entity";
import { Specification } from "./specification.entity";
import { UnitAmenity } from "./unit-amenity.entity";
import { UnitType } from "./unit-type.entity";

export interface Unit {
    id?: number;
    projectId?: number;
    is_stand_alone?: number;
    type: UnitType;
    title: string;  
    featuredImage: MediaLibraryItem | null;
    amenities: number[];
    amenitiesTitle: string;
    gallery: MediaLibraryItem[];
    plans: Plan[];
    specifications: Specification[];
    totalUnits: number;
    unitsSold: number;
    numberOfBedrooms: number;
    numberOfBathrooms: number;
    numberOfParking: number;
    numberOfBalconies: number;
    numberOfFloors: number;
    colorCode: string;
    isPriceShown: number;
    price: number;
    status: string;
    statusId: number;
    isDoubleHeight?: number;
    isFeatured?: number;
    isForSale?: number;
    isForRent?: number;
    isForLease?: number;
    isForResale?: number;
    propertyNumber?: number;
    amenityTitle?: string;
    galleryTitle: string;
    yearBuilt?: number;
    plotSize: number;
    plotSizeUnit: string;
    builtArea: number;
    builtAreaUnit: string;
    carpetArea: number;
    carpetAreaUnit: string;
    brochureUrl: string | MediaLibraryItem;
    brochureTitle: string;
    brochureImage: MediaLibraryItem | null;
    brochureUrlFilename: string;
    assignedList: UnitAssignedList[];
    projectName?: string;
    createdBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    unitNumber?: number;
    longDescription: string;
    propertyAge: string;
    numberOfFnBSpaces: number;
    numberOfConferenceHalls: number;
    washroomOptions: number[];
    parkingOptions: number[];
    facingOptions: number[];
    isPublished: number;
}

export interface UnitAssignedList {
    id: number;
    propertyId: number;
    assignedBy: number;
    assignedTo: number;
    readWrite: number;
    createdAt: Date,
    updatedAt: Date;
}

export function formatUnitAssignedList(assignedListJson: any): UnitAssignedList {
    const assignedList: UnitAssignedList = {
        id: assignedListJson.id,
        propertyId: assignedListJson.property_id,
        assignedBy: assignedListJson.assigned_by,
        assignedTo: assignedListJson.assigned_to,
        readWrite: assignedListJson.read_write,
        createdAt: new Date(assignedListJson.created_at),
        updatedAt: new Date(assignedListJson.updated_at),
    }
    return assignedList;
}

export function formatUnitJson(property: any): Unit {
    let propertyMedia: MediaLibraryItem[] = [];
    let featuredImage: MediaLibraryItem = {} as MediaLibraryItem;
    if(property.property_medias.length) {
        propertyMedia = property.property_medias.map((media: any) => {
            media.id = media.media_id;
            return media;
        })
        featuredImage = propertyMedia.find((media: MediaLibraryItem) => media.is_featured === 1)!;
    }
    const unit: Unit = {
        id: property.id,
        projectId: property.project_id,
        type: property.unit_types?.length? property.unit_types[0] : {} as UnitType,
        title: property.title,
        featuredImage: featuredImage,
        amenities: property.property_amenities.map((amenity: UnitAmenity) =>amenity.id),
        amenitiesTitle: property.amenity_title || '',
        gallery: propertyMedia,
        plans: property.property_plans.map((plan: Plan) => formatPlanJson(plan)),
        specifications: property.specification? property.specification.map((el: string) => JSON.parse(el)) : [],
        totalUnits: property.total_units,
        unitsSold: property.unit_sold,
        numberOfBedrooms: property.no_of_bedrooms,
        numberOfBathrooms: property.no_of_bathrooms,
        numberOfParking: property.no_of_parkings,
        numberOfBalconies: property.no_of_balconies,
        numberOfFloors: property.no_of_floors,
        colorCode: property.color_code,
        isPriceShown: property.is_price_shown,
        price: +property.price,
        status: property.status,
        statusId: property.status_id,
        isDoubleHeight: property.is_double_height,
        isFeatured: property.is_featured,
        isForSale: property.is_for_sale,
        isForRent: property.is_for_rent,
        isForResale: property.is_for_resale,
        propertyNumber: property.property_number,
        amenityTitle: property.amenity_title,
        galleryTitle: property.gallery_title,
        yearBuilt: property.year_built,
        plotSize: property.plot_size,
        plotSizeUnit: property.plot_size_unit? property.plot_size_unit : 'sqmt',
        builtArea: property.built_area_unit,
        builtAreaUnit: property.built_area_unit? property.built_area_unit : 'sqmt',
        carpetArea: property.carpet_area,
        carpetAreaUnit: property.carpet_area_unit? property.carpet_area_unit : 'sqmt',
        brochureImage: property.brochure_image,
        brochureTitle: property.brochure_title,
        brochureUrl: property.brochure_url,
        brochureUrlFilename: '',
        assignedList: property.assigned_list.map((item: any) => formatUnitAssignedList(item)),
        projectName: property.project_name? property.project_name : '',
        createdBy: property.created_by? property.created_by : '',
        createdAt: property.created_at? new Date(property.created_at) : new Date(),
        updatedAt: property.updated_at? new Date(property.updated_at) : new Date(),
        unitNumber: property.property_number? property.property_number : 0,
        longDescription: property.long_description || '',
        propertyAge: property.property_age || '',
        numberOfFnBSpaces: property.no_of_fnb_spaces || 0,
        numberOfConferenceHalls: property.no_of_conference_halls || 0,
        washroomOptions: property.washroom_options? property.washroom_options.map((opt: string) => +opt) : [],
        parkingOptions: property.parking_options? property.parking_options.map((opt: string) => +opt) : [],
        facingOptions: property.facing_options? property.facing_options.map((opt: string) => +opt) : [],
        isPublished: property.is_published || 0
    }
    return unit;
}