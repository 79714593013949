import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { authGuard } from './core/guards/auth/auth.guard';

const routes: Routes = [
    {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
        path: 'auth',
        children:[
            { 
                path: 'reset_password', 
                loadComponent: () => import('./components/pages/auth/reset-password/reset-password.component')
                .then((c) => c.ResetPasswordComponent) 
            },
            {
                path: 'signin-token/:token', 
                loadComponent: () => import('./components/pages/register-token/register-token.component')
                .then((c) => c.RegisterTokenComponent) 
            }
        ]
    },
    {
        path: '',
        canActivate: [authGuard],
        children: [
           
            {
                path: 'projects',
                loadChildren: () => import('./components/pages/projects/projects.module')
                .then((m) => m.ProjectsModule)
            },
            {
                path: 'units',
                loadChildren: () => import('./components/pages/units/units.module')
                .then((m) => m.UnitsModule)
            },
            {
                path: 'leads',
                loadChildren: () => import('./components/pages/leads/leads.module')
                .then((m) => m.LeadsModule)
            },
            {
                path: 'media-library',
                loadComponent: () => import('./components/pages/media-libary/media-libary.component')
                .then((c) => c.MediaLibaryComponent)
            },
            {
                path: 'users',
                loadChildren: () => import('./components/pages/users/user.module')
                .then((c) => c.UserModule)
            },
            {
                path: 'dashboard', 
                loadComponent: () => import('./components/pages/dashboard/dashboard.component')
                .then((c) => c.DashboardComponent)
            },
            {
                path: 'profile', 
                loadComponent: () => import('./components/pages/profile/profile.component')
                .then((c) => c.ProfileComponent)
            },
            {
                path: 'approvals',
                loadChildren: () => import('./components/pages/approvals/approvals.module')
                .then((c) => c.ApprovalsModule)
            },
            { 
                path: 'coming-soon',
                loadComponent: () => import('./components/common/coming-soon/coming-soon.component')
                .then((c) => c.ComingSoonComponent)
            }
        ]
    },
    { 
        path: '**',
        loadComponent: () => import('./components/common/not-found/not-found.component')
        .then((c) => c.NotFoundComponent)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }