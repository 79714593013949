export interface Lead {
    id: number;
    userId: number;
    name: string;
    phoneNo: string;
    email: string;
    isEmailVerified: number;
    isOpen: number;
    propertyId?: number;
    projectId?: number;
    closedAt: Date
    createdAt: Date
    updatedAt: Date
    projectName: string;
    unitType?: string;
    category?: string;
    subCategory?: string;
    enquiryNotes: LeadNote[];
    assignedList: any[];
    leadTypes?: any[]
}

export interface LeadNote {
    id: number;
    propertyEnquiryId?: number;
    projectEnquiryId?: number;
    note: string;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
    profilePicture: string;
}

export function formatLeadJson(leadJson: any): Lead {
    const lead: Lead = {
        id: leadJson.id,
        userId: leadJson.user_id,
        name: leadJson.name,
        phoneNo: leadJson.phone_no,
        email: leadJson.email,
        isEmailVerified: leadJson.is_email_verified,
        isOpen: leadJson.is_open,
        propertyId: leadJson.property_id? leadJson.property_id : undefined,
        projectId: leadJson.project_id? leadJson.project_id : undefined,
        closedAt: leadJson.closed_at,
        createdAt: leadJson.created_at,
        updatedAt: leadJson.updated_at,
        projectName: leadJson.project_name,
        unitType: leadJson.unit_type? leadJson.unit_type : undefined,
        category: leadJson.category? leadJson.category : undefined,
        subCategory: leadJson.sub_category? leadJson.sub_category : undefined,
        enquiryNotes: leadJson.lead_notes.map((note: any) => formatLeadNoteJson(note)),
        assignedList: leadJson.assigned_list,
        leadTypes: leadJson.lead_types
    }
    return lead;
}

export function formatLeadNoteJson(noteJson: any): LeadNote {
    const note: LeadNote = {
        id: noteJson.id,
        propertyEnquiryId: noteJson.property_lead_id? noteJson.property_lead_id : undefined,
        projectEnquiryId: noteJson.project_lead_id? noteJson.project_lead_id : undefined,
        note: noteJson.note,
        createdBy: noteJson.created_by,
        createdAt: noteJson.created_at,
        updatedAt: noteJson.updated_at,
        profilePicture: noteJson.profile_picture
    }
    return note;
}
