import { MediaLibraryItem } from "./media-library-item.entity";

export interface Plan {
    id?: number;
    title: string;
    type: number;
    media: MediaLibraryItem;
    createdBy?: number;
    createdAt?: string;
    updatedAt?: string;
}

export function formatPlanJson(plan_json: any): Plan {
    // let media: MediaLibraryItem[] = plan_json.media.map((media: any) => {
    //     media.id = media.media_id;
    //     return media;
    // })
    const plan: Plan = {
        id: plan_json.id,
        title: plan_json.title,
        type: plan_json.type_of_plan_id,
        media: plan_json.media[0],
        createdBy: plan_json.created_by,
        createdAt: plan_json.created_at,
        updatedAt: plan_json.updated_at
    }
    return plan;
}