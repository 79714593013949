export interface ArchitectureStyle{
    id: number;
    name: string;
    createdBy?: number;
    approved: number;
    approvedBy: string | number;
    createdAt: Date;
    updatedAt: Date;
}

export function formatArchitectureStyleJson(style: any): ArchitectureStyle {
    const architectureStyle: ArchitectureStyle = {
        id: style.id,
        name: style.name,
        createdBy: style.created_by,
        approved: style.approved,
        approvedBy: style.approved_by,
        createdAt: new Date(style.created_at),
        updatedAt: new Date(style.updated_at)
    }
    return architectureStyle;
}