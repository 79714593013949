export const AREA_UNITS: { id: string, name: string}[] =
[
    { id: 'sqmt', name: 'Sq. mt.' },
    { id: 'sqft', name: 'Sq. ft.' },
    { id: 'acres', name: 'Acres' },
    { id: 'hectares', name: 'Hectares' }
];

export const PROPERTY_STATUSES: { id: number, name: string}[] = [
    { id: 1, name: 'Available' },
    { id: 2, name: 'Under Development' },
    { id: 3, name: 'Sold Out' }
]

export const ROLES: { role: string, name: string, authorityLevel: number }[] = [
    { role: 'super_admin', name: 'Super Admin', authorityLevel: 0 },
    { role: 'principal_broker', name: 'Principal Broker', authorityLevel: 1 },
    { role: 'developer', name: 'Developer', authorityLevel: 2 },
    { role: 'broker', name: 'Broker', authorityLevel: 3 },
    { role: 'broker_staff', name: 'Broker Staff', authorityLevel: 4 },
    { role: 'agent', name: 'Agent', authorityLevel: 5 },
    { role: 'user', name: 'User', authorityLevel: 6 },

]

export const DATE_FORMAT = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const API_LISTING_FLAGS = [
    { id: 1, value: 0, name: 'all' },
    { id: 2, value: 1, name: 'approved' },
    { id: 3, value: 2, name: 'pending' },
    { id: 4, value: 3, name: 'rejected' },
    { id: 5, value: 4, name: 'approved&pending' },
]