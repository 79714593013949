<div class="sidebar-area bg-white box-shadow" [ngClass]="{'active': isToggled}"
    [class.dark-sidebar]="themeService.isSidebarDark()" [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()">
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img [src]="user.logoUrl? user.logoUrl : 'assets/img/logo-icon.png'" alt="logo">
            <br>
            <span>{{getCompanyName()}}</span>
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    @if(userRole.authorityLevel < 3) { <a routerLink="/" class="sidebar-menu-link"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-brainstorm"></i>
                        <span class="title">Dashboard</span>
                        </a>
                        }


                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-user"></i>
                                    <span class="title">Developer</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a routerLink="/users/partners/approved" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Developer List
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/users/partners/pending" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Pending Approvals
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/users/partners/rejected" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Rejected Developers
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        @if(userRole.authorityLevel === 0) {
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-user"></i>
                                    <span class="title">Brokerage</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a routerLink="/users/brokers/approved" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Brokerage List
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/users/brokers/pending" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Pending Approval
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/users/brokers/rejected" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Rejected Brokerages
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/users/agents" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Agents
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        }

                        @if(userRole.authorityLevel < 4) { <mat-expansion-panel (opened)="panelOpenState = true"
                            (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-user"></i>
                                    <span class="title">User Management</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a routerLink="/coming-soon" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Users
                                    </a>
                                </li>
                            </ul>
                            </mat-expansion-panel>
                            }


                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <i class="flaticon-user"></i>
                                        <span class="title">CMS</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul class="sidebar-sub-menu">
                                    <li class="sidemenu-item">

                                        <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = true">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <i class="flaticon-user"></i>
                                                    <span class="title">Listing Management</span>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ul class="sidebar-sub-menu">
                                                <li class="sidemenu-item">
                                                    <a routerLink="/projects/add" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                        Add Listing
                                                    </a>
                                                </li>

                                                <li class="sidemenu-item">
                                                    <a routerLink="/projects/list" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                        My Listings
                                                    </a>
                                                </li>

                                                @if(userRole.authorityLevel === 0) {
                                                <li class="sidemenu-item">
                                                    <a routerLink="/projects/features" class="sidemenu-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        Project Features
                                                    </a>
                                                </li>

                                                <li class="sidemenu-item">
                                                    <a routerLink="/projects/architecture-styles" class="sidemenu-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        Architecture Styles
                                                    </a>
                                                </li>

                                                <li class="sidemenu-item">
                                                    <a routerLink="/projects/locations" class="sidemenu-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        Locations
                                                    </a>
                                                </li>

                                                <li class="sidemenu-item">
                                                    <a routerLink="/units/amenities" class="sidemenu-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        Amenities
                                                    </a>
                                                </li>

                                                <li class="sidemenu-item">
                                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                                        (closed)="panelOpenState = true">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <span class="title">Categories</span>
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <ul class="sidebar-sub-menu">
                                                            <li class="sidemenu-item">
                                                                <a routerLink="/units/categories/property-category"
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}"
                                                                    class="sidemenu-link">
                                                                    Property Category
                                                                </a>
                                                            </li>
                                                            <li class="sidemenu-item">
                                                                <a routerLink="/units/categories/property-sub-category"
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}"
                                                                    class="sidemenu-link">
                                                                    Property Type
                                                                </a>
                                                            </li>
                                                            <li class="sidemenu-item">
                                                                <a routerLink="/units/categories/unit-types"
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{exact: true}"
                                                                    class="sidemenu-link">
                                                                    Property Unit
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </mat-expansion-panel>
                                                </li>
                                                }

                                            </ul>
                                        </mat-expansion-panel>
                                    </li>
                                </ul>
                            </mat-expansion-panel>

                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <i class="flaticon-house"></i>
                                        <span class="title">Pre-Sales</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul class="sidebar-sub-menu">
                                    <li class="sidemenu-item">
                                        <a routerLink="/leads/list" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                            Lead Generation
                                        </a>
                                    </li>

                                    <li class="sidemenu-item">
                                        <a routerLink="/coming-soon" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                            Prospecting
                                        </a>
                                    </li>

                                    <li class="sidemenu-item">
                                        <a routerLink="/coming-soon" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                            Lead Management
                                        </a>
                                    </li>
                                </ul>
                            </mat-expansion-panel>

                            @if(userRole.authorityLevel < 4) { <a routerLink="/coming-soon" class="sidebar-menu-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <i class="flaticon-house"></i>
                                <span class="title">Analytics</span>
                                </a>
                                }

                                <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-house"></i>
                                <span class="title">Analytics</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                
                            </li>
                        </ul>
                    </mat-expansion-panel> -->

                                @if(userRole.authorityLevel < 4) { <mat-expansion-panel (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <i class="flaticon-house"></i>
                                            <span class="title">Post Sales</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ul class="sidebar-sub-menu">
                                        <li class="sidemenu-item">
                                            <a routerLink="/coming-soon" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                Documentation
                                            </a>
                                        </li>
                                        <li class="sidemenu-item">
                                            <a routerLink="/coming-soon" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                Sales Agreement
                                            </a>
                                        </li>
                                    </ul>
                                    </mat-expansion-panel>
                                    }

                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = true">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <i class="flaticon-house"></i>
                                                <span class="title">CRM</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <ul class="sidebar-sub-menu">
                                            <li class="sidemenu-item">
                                                <a routerLink="/leads/enquiries" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                    Contact
                                                </a>
                                            </li>
                                        </ul>
                                    </mat-expansion-panel>

                                    <a routerLink="/coming-soon" class="sidebar-menu-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class="flaticon-to-do-list"></i>
                                        <span class="title">Tasks</span>
                                    </a>

                                    <a routerLink="/coming-soon" class="sidebar-menu-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class="flaticon-house"></i>
                                        <span class="title">Viewings</span>
                                    </a>

                                    @if(userRole.authorityLevel < 4) { <a routerLink="/media-library"
                                        class="sidebar-menu-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <i class="flaticon-gallery"></i>
                                        <span class="title">Media Library</span>
                                        </a>
                                        }

                                        <a routerLink="/coming-soon" class="sidebar-menu-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            <i class="flaticon-phone-call"></i>
                                            <span class="title">Alerts</span>
                                        </a>

                                        <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-project-management"></i>
                                <span class="title">Projects</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                @if(userRole.authorityLevel < 3) {
                                    <a routerLink="/projects/list"
                                    class="sidemenu-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                        View Projects
                                    </a>                                 
                                }
                                @else {
                                    <a routerLink="/projects/list"
                                    class="sidemenu-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                        List Projects
                                    </a>
                                }
                            </li>
                            @if(userRole.authorityLevel < 2) {
                                <li class="sidemenu-item">
                                    <a routerLink="/projects/features"
                                    class="sidemenu-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                        Project Features
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/projects/architecture-styles"
                                    class="sidemenu-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                        Architecture Styles
                                    </a>
                                </li>
                                 <li class="sidemenu-item">
                                    <a routerLink="/projects/locations"
                                    class="sidemenu-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                        Locations
                                    </a>
                                </li> 
                            }
                        </ul>
                    </mat-expansion-panel>
                    @if(userRole.authorityLevel < 2) {
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-house"></i>
                                    <span class="title">Units</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a routerLink="/units/list" class="sidemenu-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        Manage Units
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a routerLink="/units/amenities" class="sidemenu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        Amenities
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <span class="title">Categories</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <ul class="sidebar-sub-menu">
                                            <li class="sidemenu-item">
                                                <a routerLink="/units/categories/property-category" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                    Property Category
                                                </a>
                                            </li>
                                            <li class="sidemenu-item">
                                                <a routerLink="/units/categories/property-sub-category" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                    Property Type
                                                </a>
                                            </li>
                                            <li class="sidemenu-item">
                                                <a routerLink="/units/categories/unit-types" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                                    Property Unit
                                                </a>
                                            </li>
                                        </ul>
                                    </mat-expansion-panel>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    }
                    

                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-user"></i>
                                <span class="title">Partner With Us</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            @if(userRole.authorityLevel < 2) {
                                <li class="sidemenu-item">
                                    
                                </li>
                                <li class="sidemenu-item">
                                    
                                </li>
                            }                         
                            <li class="sidemenu-item">
                                <a routerLink="/users/agents" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Agents
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>

                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-phone-call"></i>
                                <span class="title">Get In Touch</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/leads/list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Leads
                                </a>
                            </li>
                        
                            <li class="sidemenu-item">
                                <a routerLink="/leads/enquiries" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Enquiries
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel> -->
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>