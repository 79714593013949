import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { formatUserJson, User } from '../entities';
import { LocalStorageService } from './local-storage.service';
import { environment } from "../../environments/environment";
import { ROLES } from '../helpers/constants';

const baseUrl = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})

export class AuthService{
    private roles = ROLES
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
        this.currentUserSubject = new BehaviorSubject(this.localStorageService.getItem('user')!);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentUserId(): number {
        return this.currentUserSubject.value.id;
    }

    public get currentUserRole(): { role: string; name: string; authorityLevel: number; } {
        const role = this.roles.find(r => r.role === this.currentUserSubject.value.role);
        return role!;
    }
    
    register(name: string, email: string, mobile: string, password: string): Observable<any> {
        const body = {
            name: name,
            email: email,
            mobile: mobile,
            password: password
        }
        return this.http.post(baseUrl + '/register', body);
    }

    login(email: string, password: string){
        let formData: FormData = new FormData(); 
        formData.append('email', email); 
        formData.append('password', password);    
        return this.http.post(baseUrl + '/login', formData).pipe(
            map((response: any) => {
                const user = formatUserJson(response.user)
                this.localStorageService.setItem('user', user);
                this.localStorageService.setItem('auth', response.user.token);
                this.currentUserSubject.next(user);
                return response.user;
            })
        );
    }

    sendOtp(email: string): Observable<any> {
        const body = {
            email: email
        }
        return this.http.post(baseUrl + '/send_otp', body);
    }
    
    verifyOtp(email: string, otp: string): Observable<any> {
        const body = {
            email: email,
            otp: otp
        }
        return this.http.post(baseUrl + '/verify_otp', body);
    }

    logout(): void {
        this.localStorageService.clearStorage();
        window.location.href = environment.siteUrl + "/home?newSession=1";
    }

    registerToken(token: string): void {
        this.localStorageService.setItem('auth', token);
    }

    getUserByToken(): Observable<User> {
        return this.http.get<{status: string, message: string, user: any[]}>(baseUrl + '/get_user_by_token')
        .pipe(map(response => {
            const user = formatUserJson(response.user[0])
            this.localStorageService.setItem('user', user);
            this.currentUserSubject.next(user);
            return user;
        }))
    }

    resetPassword(email: string) {
        let formData: FormData = new FormData();
        formData.append('email', email);
        formData.append('status', '' + status);
        formData.append('type', 'broker');
        return this.http.post(baseUrl + '/reset_password', formData)
    }

    updatePassword(url: string, password: string) {
        let formData: FormData = new FormData();
        formData.append('url_string', url);
        formData.append('password', password);
        return this.http.post(baseUrl + '/update_password', formData)
    }


}
