export interface MediaLibraryItem {
    id: number;
    media_url: string;
    media_id?: number;
    is_featured?: number;
    created_by: string;
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    alt_text: string;
    caption: string;
}