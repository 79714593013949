export interface WashroomOption {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export function formatWashroomOptionJson(washroomOptionJson: any): WashroomOption {
    const washroomOption: WashroomOption = {
        id: washroomOptionJson.id,
        name: washroomOptionJson.name,
        createdAt: new Date(washroomOptionJson.created_at),
        updatedAt: new Date(washroomOptionJson.updated_at)
    }
    return washroomOption
}