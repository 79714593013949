export interface ParkingOption {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export function formatParkingOptionJson(parkingOptionJson: any): ParkingOption {
    const parkingOption: ParkingOption = {
        id: parkingOptionJson.id,
        name: parkingOptionJson.name,
        createdAt: new Date(parkingOptionJson.created_at),
        updatedAt: new Date(parkingOptionJson.updated_at)
    }
    return parkingOption
}