export interface Broker {
    id: number;
    name: string;
    email: string;
    mobile: string;
    businessName: string;
    type: string;
    status: number;
    createdAt: Date;
    updatedAt: Date;
}

export function formatBrokerJson(brokerJson: any): Broker {
    const broker: Broker = {
        id: brokerJson.id,
        name: brokerJson.name,
        email: brokerJson.email,
        mobile: brokerJson.mobile,
        businessName: brokerJson.business_name,
        type: brokerJson.type,
        status: brokerJson.status,
        createdAt: new Date(brokerJson.created_at),
        updatedAt: new Date(brokerJson.updated_at)
    }
    return broker
}