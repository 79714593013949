export interface UnitType {
    tableId?: number;
    id: number;
    name: string;
    property_sub_category_id: number;
    property_category_id: number;
    created_by: string;
    created_at: string;
    updated_at: string;
    category: string;
    sub_category: string;
    is_selected?: boolean;
    display_title?: string;
}
