export interface User {
    tableId?: number;
    id: number;
    name: string;
    email: string;
    mobile: number;
    password: string;
    role: string;
    createdBy: string;
    isActive: number;
    isVerified: number;
    lastLoginAt: Date;
    businessName: string;
    businessAddresss: string;
    businessPointOfContact: string;
    logoUrl: string;
    websiteUrl: string | null;
    isPrincipalBroker: number;
    createdAt: Date;
    updatedAt: Date;
    token: string;
    gstn: string | null;
    profilePicture: string;
    profileDescription: string;
    specialization: number[];
}

export function formatUserJson(userJson: any): User {
    const user: User = {
        tableId: 0,
        id: userJson.id,
        name: userJson.name,
        email: userJson.email,
        mobile: userJson.mobile,
        password: userJson.password,
        role: userJson.role,
        createdBy: userJson.created_by,
        isActive: userJson.is_active,
        isVerified: userJson.is_verified,
        lastLoginAt: new Date(userJson.last_login_at),
        businessName: userJson.business_name,
        businessAddresss: userJson.business_addresss,
        businessPointOfContact: userJson.business_point_of_contact,
        logoUrl: userJson.logo_url,
        websiteUrl: userJson.website_url,
        isPrincipalBroker: userJson.is_principal_broker,
        createdAt: new Date(userJson.created_at),
        updatedAt: new Date(userJson.updated_at),
        token: userJson.token,
        gstn: userJson.gstn,
        profilePicture: userJson.profile_picture,
        profileDescription: userJson.profile_description,
        specialization: userJson.sub_category_ids
    }
    return user;
}