import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})

class PermissionsService {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.authService.currentUserValue) {
      return true;
    } else {
      // this.router.navigate(['/']);
      window.location.href = environment.siteUrl + "/home?newSession=1";
      return false;
    }
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate(route, state);
};