import { Component, HostListener } from '@angular/core';
import { ToggleService } from './toggle.service';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/entities';
import { ROLES } from 'src/app/core/helpers/constants';
import { ConfirmModalDialogComponent } from '../confirm-modal-dialog/confirm-modal-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, first } from 'rxjs';
import { AlertModalDialogComponent } from '../alert-modal-dialog/alert-modal-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule
    ]
})
export class HeaderComponent {
    subscriptions: Subscription[] = [];
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    isToggled = false;
    user: User;
    roles = ROLES;

    constructor(
        private dialog: MatDialog,
        private toggleService: ToggleService,
        private datePipe: DatePipe,
        public themeService: CustomizerSettingsService,
        private authService: AuthService
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.user = this.authService.currentUserValue;
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleHeaderTheme() {
        this.themeService.toggleHeaderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    getRoleName(role: string): string {
        const roleObj = this.roles.find(r => r.role === role);
        if (roleObj) {
            return roleObj.name;
        }
        else {
            return role;
        }
    }

    currentDate: Date = new Date();
    formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');

    logout() {
        this.authService.logout();
    }

    openResetPasswordModal() {
        const dialogRef = this.dialog.open(ConfirmModalDialogComponent,
            { enterAnimationDuration:'200ms',
            maxWidth: '600px',
            width: '80%',
            data: {
              message: `Are you sure you want to reset your password?`
            }
        });
      
        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.subscriptions.push(
                this.authService.resetPassword(this.user.email)
                .pipe(first()).subscribe({
                    next: (response: any) => {
                        if(response.status) {
                            this.openAlertDialog('Password reset successful, please check your email for the password link.');
                        }
                        else {
                            this.openAlertDialog('Password reset failed, please try again');                 
                        }
                    },
                    error: (error: any) => {
                        this.openAlertDialog('Password reset failed, please try again');                 
                    }
                }))
            }
        });
    }

    openAlertDialog(message: string) {
        const dialogRef = this.dialog.open(AlertModalDialogComponent,
          { enterAnimationDuration:'200ms',
          maxWidth: '600px',
          width: '80%',
          data: {
            message: message
          }
        });
      }
}