export interface Location {
    tableId?: number;
    id: number;
    city_id: number;
    state_id: number;
    district_id: number;
    country_id: number;
    neighbourhood: string;
    pincode: string;
    lat: string;
    lng: string;
    created_by: number;
    created_at: string;
    updated_at: string;
    country: string;
    state: string;
    city: string;
}