export interface FacingOption {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export function formatFacingOptionJson(facingOptionJson: any): FacingOption {
    const facingOption: FacingOption = {
        id: facingOptionJson.id,
        name: facingOptionJson.name,
        createdAt: new Date(facingOptionJson.created_at),
        updatedAt: new Date(facingOptionJson.updated_at)
    }
    return facingOption
}