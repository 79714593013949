import { MediaLibraryItem } from "./media-library-item.entity";

export interface DirectoryStructure {
    id: number;
    folderName: string;
    type: string;
    parentId: number;
    mediaId: number;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
    media: MediaLibraryItem;
    child: DirectoryStructure[];
}

export function formatDirectoryStructureJson(structure: any): DirectoryStructure {
    const directoryStructure: DirectoryStructure = {
        id: structure.id,
        folderName: structure.folder_name,
        type: structure.type,
        parentId: structure.parent_id,
        mediaId: structure.media_id,
        createdBy: structure.created_by,
        createdAt: new Date(structure.created_at),
        updatedAt: new Date(structure.updated_at),
        media: structure.media,
        child: structure.child.map((ch: any) => formatDirectoryStructureJson(ch))
    }
    return directoryStructure;
}
