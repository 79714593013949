import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-modal-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './alert-modal-dialog.component.html',
  styleUrl: './alert-modal-dialog.component.scss'
})
export class AlertModalDialogComponent implements OnDestroy, OnInit {
  constructor(
    public dialogRef: MatDialogRef<AlertModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
  }
}
