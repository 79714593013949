import { Location } from "./location.entity";

export interface Enquiry {
    id: number;
    type: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNo: number;
    email: string;
    propertySubCategory_id: number;
    locations: Location[];
    budget: number;
    buyingPurposeId: number;
    closedAt: Date;
    closedBy: Date;
    isOpen: number;
    createdAt: Date;
    updatedAt: Date;
    subCategories: any[];
    buyingPurpose: string;
    location: Location;
    enquiryNotes: EnquiryNote[];
    assignedList: any[];
}

export interface EnquiryNote {
    id: number;
    enquiryId: number;
    note: string;
    createdBy: Date;
    createdAt: Date;
    updatedAt: Date;
    profilePicture: string;
}

export function formatEnquiryJson(enquiryJson: any): Enquiry {
    const enquiry: Enquiry = {
        id: enquiryJson.id,
        type: enquiryJson.type,
        name: enquiryJson.name,
        phoneNo: enquiryJson.phone_no,
        propertySubCategory_id: enquiryJson.property_sub_category_id,
        locations: enquiryJson.locations,
        budget: enquiryJson.budget,
        buyingPurposeId: enquiryJson.buying_purpose_id,
        closedAt: enquiryJson.closed_at,
        closedBy: enquiryJson.closed_by,
        isOpen: enquiryJson.is_open,
        createdAt: enquiryJson.created_at,
        updatedAt: enquiryJson.updated_at,
        subCategories: enquiryJson.sub_categories,
        buyingPurpose: enquiryJson.buying_purpose,
        location: enquiryJson.location,
        enquiryNotes: enquiryJson.enquiry_notes.map((note: any) => formatEnquiryNoteJson(note)),
        assignedList: enquiryJson.assigned_list,
        firstName: enquiryJson.first_name,
        lastName: enquiryJson.last_name,
        email: enquiryJson.email
    }
    return enquiry;
}

export function formatEnquiryNoteJson(enquiryNoteJson: any): EnquiryNote {
    const enquiryNote: EnquiryNote = {
        id: enquiryNoteJson.id,
        enquiryId: enquiryNoteJson.enquiry_id,
        note: enquiryNoteJson.note,
        createdBy: enquiryNoteJson.created_by,
        createdAt: enquiryNoteJson.created_at,
        updatedAt: enquiryNoteJson.updated_at,
        profilePicture: enquiryNoteJson.profile_picture
    }
    return enquiryNote;
}